import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import last from "../Assets/feature2-1.webp"
import test1 from "../Assets/team1-1.webp"
import comment from "../Assets/commennt.webp"
import test2 from "../Assets/team1-2.webp"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaArrowLeft, FaArrowRight, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const testimonials = [
    {
        text: "Outstanding Service,NxEdges reliable logistics solutions have streamlined our operations. Their customer support is exceptional and always available.",
        img: test1,
        name: "Sarah Smith",
        profession: "Operations Manager, TechWare Inc."
    },
    {
        text: "Efficient and Timely Consistently timely deliveries and efficient handling of our shipments. NxEdge has been a dependable partner for our logistics needs.",
        img: comment,
        name: "Mark Johnson",
        profession: "Logistics Coordinator, FreshFoods Ltd."
    },
   
    {
        text: "Sustainable Practices NxEdge's commitment to sustainability aligns perfectly with our values. Their green logistics practices are a significant advantage.",
        img: test1,
        name: " Michael Lee, Sustainability Director",
        profession: "Sustainability Director, EcoGoods Co"
    },
    {
        text: " Innovative Technology Real-time tracking and advanced technology have made our supply chain more transparent and manageable. Highly recommended.",
        img: test2,
        name: "Emily Davis",
        profession: "Supply Chain Analyst, RetailWorks"
    },
];

const Testimonials = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
            once: true,
        });
    }, []);
    const sliderRef = useRef(null);

    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        speed: 400,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className="features-section-two">
        <div className="bg testimonial-image"></div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-7" data-aos="fade-up">
                    <div className="testimonials-two-wrap mb-55">
                        <div className="d-flex align-items-center">
                            <div className="section-title mb-50">
                                <span className="sec-title">Testimonials</span>
                                <h2>What Our Customers Say About Our Products</h2>
                            </div>
                            <div className="testimonials-arrow pt-25 rpt-10">
                                <button className="testi-prev" onClick={() => sliderRef.current.slickPrev()}>
                                    <FaArrowLeft />
                                </button>
                                <button className="testi-next" onClick={() => sliderRef.current.slickNext()}>
                                    <FaArrowRight />
                                </button>
                            </div>
                        </div>
                        <Slider ref={sliderRef} {...settings} className="testimonials-slider">
                            {testimonials.map((testimonial, index) => (
                                <div className="testimonial-item-two" key={index} data-aos="fade-up">
                                    <div className="text">
                                        <FaQuoteLeft className='svg-one' />
                                        {testimonial.text}
                                        <FaQuoteRight className='svg-two' />
                                    </div>
                                    <div className="testi-footer">
                                        <img src={testimonial.img} alt="Testimonial Author" />
                                        <div className="author">
                                            <h6>{testimonial.name}</h6>
                                            <span className="designation">{testimonial.profession}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div className="col-md-5" >
                    <div className="testimonials-image-two mb-55">
                        <img className="" src={last} alt="Testimonials" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Testimonials;
