import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/2150895675.webp"
import image2 from "../Assets/1200.webp"
import image3 from "../Assets/air.webp"
import heavyhaul from "../Assets/Air-Freight-101.png"

import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';


function AirFreight() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Air Freight</h1>
        <ul className="page-breadcrumb">
         
          <li>At NxEdge Logistics, we understand the critical importance of speed and reliability in air freight transportation. Our comprehensive air freight services are designed to meet the demands of your business, ensuring your shipments reach their destination quickly and securely.Need a fast and reliable air freight solution? NxEdge Logistics is here to help. Get in touch with our air freight experts today to discuss your requirements and find the best solution for your business.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

      <section className="single-service">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>NxEdge Logistics: Empowering Freight Solutions </h2>
                  <p>
                <strong> NxEdge Logistics</strong> provides dependable air freight options that guarantee prompt and economical delivery of your goods. We manage your logistics with accuracy and care, optimising routes and cutting down on transit times to effectively fulfil your business demands thanks to our vast network and cutting-edge tracking.
                  </p>
                  <p>Use the air freight services from NxEdge to streamline your supply chain. We provide dependable, affordable options for small-load shipping across the globe. Take advantage of our broad network, sophisticated tracking, and dedication to on-time delivery. You can rely on NxEdge for excellent customer service and effective international logistics. </p>

                  <p>With a focus on air freight and global logistics, NxEdge makes sure your items get at their destination quickly and affordably. Our world-class tracking technologies and committed support staff offer flawless logistical solutions. You can rely on NxEdge for dependable, prompt delivery and excellent service while handling your</p>
                  <h3>NxEdge Logistics: Air Freight Specialists </h3>
                  <img src={image2} alt="" />
                  <p>NxEdge offers top-tier Air freight solutions, ensuring efficient and cost-effective transportation for smaller shipments worldwide. Our global logistics network guarantees timely delivery, precise tracking, and unmatched reliability. Partner with NxEdge to streamline your supply chain and meet all your shipping needs with confidence and ease. </p>
                  <h5>Are you searching for the best air freight services in the US? NxEdge Logistics offers reliable and innovative solutions.</h5>
                  <p>NxEdge specialises in air freight solutions, therefore regardless of the size of your cargo, we can guarantee quick and economical delivery. Your logistics will run smoothly thanks to our vast network and cutting-edge tracking technology, which ensure dependable service and real-time updates. We handle international shipments with the same attention and precision, adhering to intricate customs and procedures, as part of our dedication to having a global reach. Put your trust in NxEdge for all of your international logistics needs, and benefit from unmatched customer service and efficiency. Regardless of the distance, we consistently deliver excellence.
                  </p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>NxEdge Logistics: Seamlessly Navigate Air Freight Solutions </h6>
                      <p>
                      NxEdge offers excellent air freight solutions that ensure affordable and efficient shipment of small products worldwide. Our global logistics network guarantees delivery on time, precise tracking, and unparalleled dependability. Work together with NxEdge to streamline your supply chain and handle all of your shipping needs with ease and confidence.

                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy-haul"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/air-freight">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean-freight">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/warehousing">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply-chain">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
          </div>
        </div>
      </section>

      <Process />
    </>
  );
}

export default AirFreight;


