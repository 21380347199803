import React, { useEffect, useState } from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';
import air from "../Assets/flight-delivery.png";
import { Link } from "react-router-dom";
import Testimonials from "./Testimonial";
import serve from "../Assets/serv-icon-4-1.png";
import serve1 from "../Assets/serv-icon-6-1.png";
import serve3 from "../Assets/serv-icon-2-1.png";
import serve4 from "../Assets/serv-icon-1-1.png";
import ocean from "../Assets/ocean-freight-blog.jpg";
import ware from "../Assets/warehouse3.jpg";
import heavy from "../Assets/heavy.jpeg";
import service from "../Assets/service-supply-chain-1-q7denk5wliyft7zwtg0uqmekanjchokj7ss9x6sj4g.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import move from "../Assets/Moving--Sto.jpg";

import second from "../Assets/request-one_pattern.webp";
import move2 from "../Assets/custom_solutions.jpg";
import track from "../Assets/track-order.jpg";
import Choose from "./Choose";
import Counter from "./Counter";

import FormMain from "./Form";
import Process from "./Process";
import { FaArrowCircleRight, FaUser } from "react-icons/fa";

import delivery4 from "../Assets/cost.webp";
import Common from "./Common";
import {
  FaBoxesStacked,
  FaDiamondTurnRight,
  FaTruckFront,
} from "react-icons/fa6";

import sliderImg1 from "../Assets/home-7-slide3.png";
import sliderImg2 from "../Assets/home-7-slide2.png";
import sliderImg3 from "../Assets/home-7-slide4.png";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1200, // Customize this value as needed
    });
  }, []);

  
 
  const contentData = [
    {
      image: sliderImg1,
      title: "Collaboration With Chat",
      mainText: "Better Design For Digital Products.",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.",
    },
    {
      image: sliderImg2,
      title: "Excellent IT Services For Your Success",
      mainText: "Crafting Unique Digital .",
      description:
        "Unlock your business potential with our comprehensive IT services. We're here to support your journey to success.",
    },
    {
      image: sliderImg3,
      title: "Success Through Digital Innovation",
      mainText: "Delivering Quality Results.",
      description:
        "Achieve your business goals with our comprehensive and customized digital marketing services.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run only once on mount

  const { image } = contentData[currentIndex];

  return (
    <>
      <div className="hero-main">
        <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-gradient">
          <div className="inner-column" data-aos="zoom-in">
            <div className="sec-title light sec-title-main">
              <span className="sub-title">nxedge logistics</span>
              <h2>Providing full range Logistics Solution</h2>

              <p>
                NxEdge Logistics is a comprehensive logistics and supply chain
                management company offering a wide range of services to meet the
                diverse needs of businesses and individuals.
              </p>
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">REQUEST A QUOTE </span>
                </Link>
              </div>
            <img className="air-flight" src={air} alt="" />
          </div>
        </div>

        <div
          className=" position-relative hero-main-image"
          data-aos="zoom-in"
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            zIndex:'-1',
            margin: "40px 0",
            height: window.innerWidth <= 768 ? "400px" : "700px", // Conditionally set height based on window width
            transition: "background-image 1s ease-in-out",
          }}
        ></div>
      </div>

      <section className="about-section-three">
        <div className="container">
          <div className="d-flex align-items-center " data-aos="fade-left">
            <div className="section-title mb-50">
              <span className="sec-title">WHAT WE DO</span>
              <h2>Moving Your Products Across All Borders</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="service-inner">
                <div className="media" data-aos="flip-left">
                  <div className="post-thumb">
                    <Link to="/supply-chain" className="post-image-link">
                      <img
                        decoding="async"
                        src={service}
                        title="service-supply-chain"
                        alt=""
                        className="img-first rounded-circle"
                      />
                    </Link>
                    <div className="service-icon-img-wrap">
                      <img
                        decoding="async"
                        src={serve1}
                        className="img-fluid service-icon-img"
                        alt="Supply Chain"
                      />
                    </div>
                  </div>
                  {/* .post-thumb */}
                  <div className="media-body">
                    <div className="entry-title">
                      <h4 className="post-title-head">
                        <Link to="/supply-chain" className="post-title">
                          Supply Chain
                        </Link>
                      </h4>
                    </div>

                    <div className="post-excerpt">
                      <p>
                        End-to-end supply chain solutions tailored to the
                        specific needs of businesses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="service-inner">
                <div className="media" data-aos="flip-right">
                  <div className="post-thumb">
                    <Link to="/warehousing" className="post-image-link">
                      <img
                        decoding="async"
                        src={ware}
                        alt=""
                        className="img-first rounded-circle"
                      />
                    </Link>
                    <div className="service-icon-img-wrap">
                      <img
                        decoding="async"
                        src={serve}
                        className="img-fluid service-icon-img"
                        alt="Supply Chain"
                      />
                    </div>
                  </div>

                  {/* .post-thumb */}
                  <div className="media-body">
                    <div className="entry-title">
                      <h4 className="post-title-head">
                        <Link to="/warehousing" className="post-title">
                          Smart Warehousing
                        </Link>
                      </h4>
                    </div>

                    <div className="post-excerpt">
                      <p>
                        Secure storage facilities with state-of-the-art
                        inventory management systems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="service-inner">
                <div className="media" data-aos="flip-up">
                  <div className="post-thumb">
                    <Link to="/ocean-freight" className="post-image-link">
                      <img
                        decoding="async"
                        src={ocean}
                        title="service-supply-chain"
                        alt=""
                        className="img-first rounded-circle"
                      />
                    </Link>
                    <div className="service-icon-img-wrap">
                      <img
                        decoding="async"
                        src={serve3}
                        alt="Supply Chain"
                        className="img-fluid service-icon-img"
                      />
                    </div>
                  </div>
                  {/* .post-thumb */}
                  <div className="media-body">
                    <div className="entry-title">
                      <h4 className="post-title-head">
                        <Link to="/ocean-freight" className="post-title">
                          Ocean Freight
                        </Link>
                      </h4>
                    </div>

                    <div className="post-excerpt">
                      <p>
                        Cost-effective and reliable sea transport for large and
                        bulk shipments
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="service-inner">
                <div className="media" data-aos="flip-down">
                  <div className="post-thumb">
                    <Link to="/heavy-haul" className="post-image-link">
                      <img
                        decoding="async"
                        src={heavy}
                        title="service-supply-chain"
                        alt=""
                        className="img-first rounded-circle"
                      />
                    </Link>
                    <div className="service-icon-img-wrap">
                      <img
                        decoding="async"
                        src={serve4}
                        className="img-fluid service-icon-img"
                        alt="Supply Chain"
                      />
                    </div>
                  </div>
                  {/* .post-thumb */}
                  <div className="media-body">
                    <div className="entry-title">
                      <h4 className="post-title-head">
                        <Link to="/heavy-haul" className="post-title">
                          Heavy Haul
                        </Link>
                      </h4>
                    </div>

                    <div className="post-excerpt">
                      <p>
                        Extensive land transport network for domestic and
                        cross-border deliveries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Common />
      <Process />
      <Choose />

      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="section-wrapper">
              <div className="section-title-wrapper animated fadeInUp" data-aos="zoom-in">
                <div className="title-wrap">
                  <span className="subtitle-dots">
                    building a better future{" "}
                  </span>
                  <h2>We Carrying the World to Your Doorstep</h2>
                </div>
                <div className="section-description"></div>
              </div>

              <div className="trust-score-wrapper">
                <p>Trust Score 4.5 (Based on 1,200 reviews)</p>
              </div>

              <div className="star-rating-wrapper animated fadeInUp" data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
                <div
                  className="elementor-star-rating"
                  title="4.5/5"
                  itemType="http://schema.org/Rating"
                  itemScope=""
                  itemProp="reviewRating"
                >
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star">★</span>
                </div>
              </div>

              <div className="btn-box button-element mt-4" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">REQUEST A QUOTE </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="main-feature">
              <div className="feature-box-border">
                <div className="icon-feature">
                  <FaBoxesStacked />
                </div>
                <h4 className="title-feature">100% Success Rate</h4>
                <p className="content">
                  NxEdge Logistics prides itself on achieving a 100% success
                  rate in delivering efficient, reliable, and timely logistics
                  solutions.
                </p>
              </div>

              <div className="feature-box">
                <div className="icon-feature">
                  <i className="bi bi-alarm-fill"></i>
                </div>
                <h4 className="title-feature">Global Sustainability</h4>
                <p className="content">
                  NxEdge Logistics is committed to global sustainability by
                  integrating eco-friendly practices into every aspect.
                </p>
              </div>
            </div>
            <div className="second-feature">
              <div className="feature-box-border">
                <div className="icon-feature">
                  <FaDiamondTurnRight />
                </div>
                <h4 className="title-feature">Highly Recommend</h4>
                <p className="content">
                  NxEdge Logistics stands out in the logistics industry for its
                  unparalleled commitment to customer satisfaction, innovation,
                  and sustainability.
                </p>
              </div>

              <div className="feature-box">
                <div className="icon-feature">
                  <FaTruckFront />
                </div>
                <h4 className="title-feature">Relentless Production</h4>
                <p className="content">
                  NxEdge Logistics exemplifies relentless production in the
                  logistics industry by continually optimizing operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-main-section bg-image-about">
        <img src={second} alt="" className="about-second-image" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="inner-column" data-aos="zoom-in">
                <div className="sec-title light" data-aos="fade-up"
     data-aos-anchor-placement="center-center">
                  <span className="sub-title text-light">EXPLORE SERVICES</span>
                  <h2 className="scrub-each text-split text-light">
                    We Provide 100% Trusted Transport Support
                  </h2>

                  <p className="text-light">
                    At NxEdge Logistics, we are committed to providing
                    unparalleled transportation and logistics solutions tailored
                    to meet the unique needs of our clients. With a focus on
                    efficiency, reliability, and customer satisfaction, we
                    ensure your goods are delivered safely and on time, every
                    time.
                  </p>
                </div>

                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-left" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
                <div className="row clearfix">
                  <div className=" about-section-images">
                    <img decoding="async" src={delivery4} alt="" />
                  </div>
                </div>
                <div className="about-content-main" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
                  <div className="about-service-main">
                    <FaUser />
                    <h6 className="">
                      Satisfied Clients
                      <br />
                      <span className="title-metre">350+</span>
                    </h6>
                  </div>

                  <div className="about-service-right">
                    <p>
                      <FaArrowCircleRight /> Managing logistics for world
                    </p>
                    <p>
                      <FaArrowCircleRight /> We Guarantee Trusted Service.
                    </p>
                    <p>
                      <FaArrowCircleRight /> Experts in technology fields
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="d-flex align-items-center" data-aos="fade-left"
     data-aos-anchor="#example-anchor"
     data-aos-offset="500"
     data-aos-duration="500">
            <div className="section-title mb-50">
              <span className="sec-title">WHAT WE DO</span>
              <h2>Moving Your Products Across All Borders</h2>
            </div>
          </div>
          <div className="feature-box-wrapper">
            <div className="feature-box-inner">
              <figure className="feature-box-img">
                <img src={track} decoding="async" />
              </figure>
              <div className="feature-box-content" >
                <div className="feature-box-number">1</div>
                <h4 className="feature-box-title">Shipment Tracking</h4>
                <div className="feature-box">
                  NxEdge Logistics provides comprehensive shipment tracking
                  solutions, allowing clients to monitor their shipments in
                  real-time from dispatch to delivery.
                </div>
              </div>
            </div>
            <div className="feature-box-inner" data-aos="fade-left"
     data-aos-anchor="#example-anchor"
     data-aos-offset="500"
     data-aos-duration="500">
              <figure className="feature-box-img">
                <img src={move2} alt="Shipment Tracking" decoding="async" />
              </figure>
              <div className="feature-box-content">
                <div className="feature-box-number">2</div>
                <h4 className="feature-box-title">Package & Storage</h4>
                <div className="feature-box">
                  NxEdge Logistics provides comprehensive shipment tracking
                  solutions, allowing clients to monitor their shipments in
                  real-time from dispatch to delivery.
                </div>
              </div>
            </div>
            <div className="feature-box-inner" data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0">
              <figure className="feature-box-img">
                <img src={move} alt="Shipment Tracking" decoding="async" />
              </figure>
              <div className="feature-box-content">
                <div className="feature-box-number">3</div>
                <h4 className="feature-box-title">Supply Chain</h4>
                <div className="feature-box">
                  NxEdge Logistics specializes in providing optimized supply
                  chain solutions that streamline operations, enhance
                  efficiency, and maximize value for our clients.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimonials />
      <Counter />

      <FormMain />
    </>
  );
};

export default Home;
