import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/chain1.webp"
import image2 from "../Assets/chain2.webp"
import image3 from "../Assets/chain3.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/1690218120179.jpg"

function SupplyChain() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Supply Chain</h1>
        <ul className="page-breadcrumb">
         
          <li>At NxEdge Logistics, we understand that an efficient supply chain is the backbone of your business. Our comprehensive supply chain solutions are designed to streamline operations, reduce costs, and enhance overall performance. Choosing NxEdge Logistics means partnering with a team dedicated to excellence in supply chain management. Our expertise, combined with innovative technology and a customer-centric approach, ensures your supply chain operates smoothly and efficiently.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

      <section className="single-service">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>NxEdge:  Supply Chain Logistics </h2>
                  <p>
                  NxEdge: Your go-to partner for international logistics, providing smooth supply chain solutions all around the world. We maximise efficiency and dependability at every stage, from procurement to distribution. You can count on NxEdge's unmatched logistical knowledge to make sure your products get at their destination safely and quickly.
                  </p>
                  <p>

NxEdge provides organisations with seamless connectivity and optimised operations by offering unmatched supply chain solutions for international logistics. Our extensive global network and state-of-the-art technologies guarantee effective cargo transportation. NxEdge is leading the way in logistics for the future with an unwavering dedication to quality and end-to-end services that are customised to meet your needs. With NxEdge, discover the power of innovation and dependability." </p>
                  <p>Certainly! Here's a brief description for your website: {""}

                    <strong>NxEdge Logistics</strong>. Of course! This is a synopsis for your website. We are your go-to partner for supply chain solutions since we have unmatched knowledge of how to handle the challenges of global logistics. NxEdge provides cutting-edge solutions that are customised to meet business needs, from efficient transportation management to optimised warehousing and distribution. NxEdge invites you to explore the logistics of the future.</p>
                  <h3>Efficiency Through NxEdge: Supply Chain Solutions </h3>
                  <img src={image2} alt="" />
                  <p>We at NxEdge redefine excellence in international logistics by providing a full range of supply chain solutions that are customised to fulfil all of your requirements. We mix state-of-the-art technology with unparalleled knowledge with an unwavering dedication to efficiency and dependability, enabling complete supply chain optimisation. Our extensive global network guarantees your items are transported smoothly between countries and continents. Our team of seasoned specialists is committed to delivering your cargo securely and on schedule each and every time, through rigorous preparation and perfect execution.


</p>
                  <h5>NxEdge Logistics: Optimising Your Logistics Process </h5>
                  <p>
                  Greetings from NxEdge, your go-to source for international logistics solutions. We at NxEdge take great satisfaction in providing a flawless supply chain experience that meets all of your needs. Our extensive global network guarantees effective international freight transit. Utilising state-of-the-art technology and inventive approaches, we maximise each stage of the logistics process, from procurement to delivery. Put your trust in NxEdge to optimise cost-effectiveness, improve visibility, and streamline your supply chain. NxEdge is your reliable worldwide logistics partner. Experience our unrivalled dependability and efficiency. </p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>Supply Chain Optimisation with NxEdge Technology</h6>
                      <p>
                        <strong>NxEdge</strong> provides global logistics with all-inclusive supply chain solutions that guarantee smooth international shipping. Our knowledgeable staff optimises your supply chain to maximise effectiveness and reduce expenses by utilising state-of-the-art technologies and industry knowledge</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy-haul"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/air-freight">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean-freight">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/warehousing">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply-chain">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
          </div>
        </div>
      </section>

      <Process />
    </>
  );
}

export default SupplyChain;


