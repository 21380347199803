import React, { useEffect } from 'react'
import FormMain from './Form'


const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FormMain />
     
      <div className="map-container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3435.901291286001!2d-97.79216462463444!3d30.552092774671895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b2cb43dccfe4f%3A0x2c634cd09a287833!2s3018%20Palominos%20Pass%2C%20Leander%2C%20TX%2078641%2C%20USA!5e0!3m2!1sen!2sin!4v1718624020294!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    </>
  )
}

export default Contact
