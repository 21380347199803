import React, { useEffect } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import cta1 from "../Assets/cta1-1.webp";
import service1 from "../Assets/roadways.webp";
import service2 from "../Assets/service1-1.webp";
import service3 from "../Assets/service1-2.webp";
import service4 from "../Assets/truck.webp";
import service5 from "../Assets/packers.webp";
import service6 from "../Assets/women.webp";
import { Link } from 'react-router-dom';
import FormMain from './Form';

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title page-titles">
        <div className="container">
          <div className=" text-center-padding">
            <h1 className="title">Services</h1>
            <ul className="page-breadcrumb-two">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Services</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="services-section">
        <div className="container">
          <div className="outer-box-service">
            <h2>Optimizing Supply Chain Operations: Expertly Transporting, Securely Storing, and Carefully Managing Goods with Precision and Attention to Detail for Seamless Logistics Solutions.</h2>
            <div className="services-grid">
              
              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service1} alt="Service" />
                  <div className="content-service">
                    <h4 className="title-service">
                      <Link to="/heavy-haul">Heavy Haul Transportation</Link>
                    </h4>
                    <div className="text-service">
                    Delivering excellence in heavy haul transportation, NxEdge Logistics excels with a diverse fleet including lowboys, RGNs, perimeter trailers, and dual-lane trailers. Our expertise ensures safe, efficient, and timely delivery of oversized machinery and exceptionally heavy loads.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service2} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-ship"></i>
                    <h4 className="title-service">
                      <Link to="/air-freight">Rapid and Reliable Air Freight Solutions</Link>
                    </h4>
                    <div className="text-service">
                    Strategically collaborating with top carriers, our air freight service offers cost-effective and swift transportation solutions. We prioritize economical routes to ensure small and large shipments receive meticulous attention and efficiency.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service3} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/ocean-freight">Efficient and Dependable Ocean Freight Services</Link>
                    </h4>
                    <div className="text-service">
                    At the core of international logistics, our ocean freight service guarantees efficiency and reliability. We manage global shipping complexities with expertise in LCL and FCL options. Benefit from competitive rates and timely delivery to ports worldwide.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service4} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/warehousing">Comprehensive Drayage and Warehousing Solutions​</Link>
                    </h4>
                    <div className="text-service">
                    Experience unmatched drayage and warehousing services through our extensive network of over eight thousand trucks and scalable warehousing capabilities. We provide immediate capacity for drayage needs and comprehensive warehousing solutions with added-value services.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service5} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/supply-chain">Optimized Supply Chain Management</Link>
                    </h4>
                    <div className="text-service">
                    In the dynamic world of commerce, a robust supply chain is crucial. Our end-to-end supply chain management optimizes sourcing, procurement, distribution, and returns. Transform your logistics into a strategic advantage with efficient and cost-effective solutions.
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block">
                <div className="inner-box-service">
                  <img src={service6} alt="Service" />
                  <div className="content-service">
                    <i className="icon-service flaticon-truck"></i>
                    <h4 className="title-service">
                      <Link to="/packaging">Tailored Packaging Solutions</Link>
                    </h4>
                    <div className="text-service">
                    Acknowledging the significance of secure packaging, we offer tailored solutions from standard boxes to custom designs. Our focus is on protecting your goods and enhancing your brand presentation, allowing you to concentrate on core business activities.
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="bg bg-pattern-1">
            <div className="content-box-contact">
              <h2 className="title words-slide-up text-split">
                24/7 customer support any time of the day or night
              </h2>
              <div className="text">
                Customers can get help and find answers to questions as soon
              </div>
              <div className="btn-box-contact">
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">Request a Quote</span>
                </Link>
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">Contact Us</span>
                </Link>
              </div>
            </div>
            <div className="image-box-contact">
              <img src={cta1} alt="" />
            </div>
          </div>
        </div>
      </section>

      <FormMain />
    </>
  );
}

export default Service;
