
import React from "react";
import bg from "../Assets/foooter-final-bg-1.png"
import { FaPlaneUp } from "react-icons/fa6";
import { FaGlobe, FaShieldAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Counter = () => {
   
  
    
  
    return (
      <div className=" bg-dark text-white overflow-hidden">
         <img src={bg} alt="" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="text-center-two text-center" data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
                <span className="text-4xl font-weight-bold"><FaPlaneUp /></span>
                <p className="mt-4">Domestic and International Service</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-center-two text-center" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
                <span className="text-4xl font-weight-bold"><FaGlobe /></span>
                <p className="mt-4">End to End Tracking Service</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-center-two text-center" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
                <span className="text-4xl font-weight-bold"><FaShieldAlt /></span>
                <p className="mt-4">Safe and Guaranteed Assured</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-center-two text-center" data-aos="fade-up"
     data-aos-anchor-placement="top-center"> 
              <div class="footer-contact-widget">
    <Link to="tel: +1-877-200-9408" class="phone"> +1-877-200-9408</Link>
    <div class="title">FEEL FREE TO CALL US</div>
</div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    );
  };
  
  export default Counter;