import React, { useEffect } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import image1 from "../Assets/privacy-policy.jpg";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="  text-center-padding">
            <h1 className="title">Privacy</h1>
            <ul className="page-breadcrumb-two">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Privacy & Policy</li>
            </ul>
          </div>
        </div>
      </section>

        <div className="container">
      <div className="privacy">
        <div className="privacy-content">
        <h2>Privacy & Policy</h2>
        <p>
        No mobile information will be shared with third parties / affiliates for marketing / promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
        </p>
        </div>
        <img src={image1} alt="" />
        
      </div>
      </div>
    </>
  );
};

export default Privacy;
