import React, { useState, useEffect } from "react";
import "./Navbar.css"; // Assuming your styles are in this file
import "remixicon/fonts/remixicon.css";
import { Link } from "react-router-dom";
import logo from "../Assets/1664555878466.jpg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaAngleDown, FaBars, FaEnvelope, FaPhoneAlt, FaTimes } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

// Assuming you have remixicon installed
const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showMenu = () => {
    setMenuVisible(true);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };

  const handleLinkClick = () => {
    hideMenu();
  };
  const [isHovered, setIsHovered] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <header className="header" id="header">
      <nav className="nav container">
        <Link to="/">
          <img src={logo} className="image-logo" alt="Logo" />
        </Link>

        <div
          className={`nav__menu ${menuVisible ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <li className="nav__item">
              <Link to="/" className="nav__link" onClick={handleLinkClick}>
                <i className="ri-arrow-right-up-line"></i>
                <span>Home</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/about" className="nav__link" onClick={handleLinkClick}>
                <i className="ri-arrow-right-up-line"></i>
                <span>About Us</span>
              </Link>
            </li>
            <li className="nav__item nav-item">
              <Link to="/service" className="nav__link">
                <i className="ri-arrow-right-up-line"></i>
                <span onClick={handleLinkClick}>Service</span>
              </Link>
              <FaAngleDown
                className="angle-down"
                onMouseEnter={() => setIsHovered(true)}
                onClick={() => setIsHovered(!isHovered)}
              />
              {isHovered && (
                <ul
                  className="dropdown-main"
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/heavy-haul">Heavy Haul</Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/air-freight">Air Freight</Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/ocean-freight">Ocean Freight</Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/warehousing">Drayage And Warehousing​</Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/supply-chain">Supply Chain</Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/packaging">Packaging</Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav__item">
              <Link
                to="/contact"
                className="nav__link"
                onClick={handleLinkClick}
              >
                <i className="ri-arrow-right-up-line"></i>
                <span>Contact</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="tel: +1-877-200-9408 "
                className="nav__link"
                onClick={handleLinkClick}
              >
                <i className="ri-arrow-right-up-line"></i>
                <span>   <FaPhoneAlt />
                +1-877-200-9408</span>
              </Link>
            </li>
         
         
          </ul>

          <div className="nav__close" id="nav-close" onClick={hideMenu}>
            <i className="ri-close-large-line"></i>
          </div>

          <div className="nav__social">
            <li
              className="mobile-view nav-link"
              aria-current="page"
              onClick={handleShow}
            >
              <FaBars />
            </li>
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={showMenu}>
          <i className="ri-menu-line"></i>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton />
          <Offcanvas.Body className="color">
            <div id="qodef-side-area-inner">
              <div
                id="block-21"
                className="widget widget_block widget_media_image"
                data-area="side-area"
              >
                <img
                  decoding="async"
                  src={logo}
                  alt="Shine Logistics"
                  className="wp-image-8072"
                  width="220"
                  height="100"
                  sizes="(max-width: 202px) 100vw, 202px"
                />
              </div>
              <div
                id="block-22"
                className="widget widget_block"
                data-area="side-area"
              ></div>
              <div
                id="block-10"
                className="widget widget_block"
                data-area="side-area"
              >
                <div
                  style={{
                    marginBottom: "13px",
                    marginTop: "13px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  Support center 24/7 :
                </div>
               
                <p>
                  <Link
                    style={{
                      color: "#0c0c0d",
                      fontSize: "22px",
                      fontWeight: 700,
                      letterSpacing: "-0.01em",
                    }}
                    to="tel:  +1-877-200-9408"
                  >
                     <FaPhoneAlt className="nav-icon"/> 
                    +1-877-200-9408
                  </Link>
              
                </p>
              </div>
              <div
                id="block-12"
                className="widget widget_block"
                data-area="side-area"
              >
                <div className="wp-block-columns is-layout-flex wp-container-5">
                  <div className="wp-block-column is-layout-flow">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "20px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      You can find us at :
                    </div>
                    <p>
                      <Link
                        style={{
                          color: "#0c0c0d",
                          fontSize: "17px",
                          lineHeight: "22px",
                          fontWeight: 700,
                          letterSpacing: "-0.01em",
                        }}
                        to=""
                        target="_blank"
                        rel="noopener"
                     > <FaLocationDot className="nav-icon"/> 3018 Palominos pass. Leander, <br /> Texas-78741
                      </Link>
                    </p>
                  </div>
                  <div className="wp-block-column is-layout-flow">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "20px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Get in touch with us :
                    </div>
                    <p>
                      <Link
                        style={{
                          color: "#0c0c0d",
                          fontSize: "17px",
                          lineHeight: "22px",
                          fontWeight: 700,
                          letterSpacing: "-0.01em",
                        }}
                        to="mailto:contact@nxedgelogistics.com"
                      >
                    <FaEnvelope className="nav-icon"/>   contact@nxedgelogistics.com
                
                      </Link>
                      <Link
                        style={{
                          color: "#0c0c0d",
                          fontSize: "17px",
                          lineHeight: "22px",
                          fontWeight: 700,
                          letterSpacing: "-0.01em",
                        }}
                        to="mailto:invoice@nxedgelogistics.com"
                      >
                     <FaEnvelope className="nav-icon"/>  invoice@nxedgelogistics.com
                
                      </Link>
                      <Link
                        style={{
                          color: "#0c0c0d",
                          fontSize: "17px",
                          lineHeight: "22px",
                          fontWeight: 700,
                          letterSpacing: "-0.01em",
                        }}
                        to="mailto:accounting@nxedgelogistics.com"
                      >
                   
                    <FaEnvelope className="nav-icon"/>accounting@nxedgelogistics.com
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </nav>
    </header>
  );
};

export default Navbar;
