import React from "react";
import image1 from "../Assets/shape-angle4-1.png";

import footerimage from "../Assets/truck-and-flight-1.png"

const Choose = () => {
  return (
    <>
     <section className="why-choose-us-two bg bg-pattern-7">
        <div className="chhose-content" data-aos="fade-up"
     data-aos-duration="3000">
          <span className="sub-title ">WHY WE ARE BEST</span>
          <h2 className="scrub-each text-split">
            Few Reasons Why You Choose Us Protect Yourself
          </h2>
        <div className="feature-chhose-main">
        <div className="overlay-line"></div>

        <div className="feature">
      <div className="cea-featured-icon">
        <i aria-hidden="true" className="bi bi-cart-check-fill"></i>
      </div>
      <h4 className="feature-content">On - Time Delivery</h4>
      <div className="fbox-content">
      Commitment to timely delivery and reliable service to build trust and long-term relationships with clients.
      </div>
    </div>
          <div className="feature">
      <div className="cea-featured-icon">
        <i aria-hidden="true" className="bi  bi-geo-alt-fill"></i>
      </div>
      <h4 className="feature-content">Real Time Tracking</h4>
      <div className="fbox-content">
      Tailored logistics solutions to meet the unique needs of each customer.
      </div>
    </div>
          <div className="feature">
      <div className="cea-featured-icon">
        <i aria-hidden="true" className="bi  bi-headset"></i>
      </div>
      <h4 className="feature-content">24/7 Online Support</h4>
      <div className="fbox-content">
      Dedicated support team available around the clock to address inquiries and resolve issues.

      </div>
    </div>
        </div>
        </div>

        <div className="image-column " data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
          <img src={image1} alt="" />
        </div>
        <div className="image-column-second" >
       <img src={footerimage} alt="" />
       </div>
      </section>
     
    </>
  );
};

export default Choose;
