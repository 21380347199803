import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/ocean1.webp";
import image2 from "../Assets/ocean2.webp"
import image3 from "../Assets/ocean3jpeg.webp";
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/cargo.png"


function Ocean() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
  
  <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Ocean Freight</h1>
        <ul className="page-breadcrumb">
         
          <li>At NxEdge Logistics, we understand the critical importance of speed and reliability in air freight transportation. Our comprehensive air freight services are designed to meet the demands of your business, ensuring your shipments reach their destination quickly and securely.Need a fast and reliable air freight solution? NxEdge Logistics is here to help. Get in touch with our air freight experts today to discuss your requirements and find the best solution for your business.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>NxEdge Ocean Freight Logistics Solutions: Streamlined, Reliable, Global Services </h2>
                <p>
                Your Passport to World-Class Global Logistics is NxEdge. Discover our extensive ocean freight options, customised to meet your demands for international shipping. Experience smooth cargo transportation across oceans with NxEdge, guaranteeing prompt delivery and economical solutions. Have faith in our abilities to navigate the intricacies of international logistics. For effective, dependable, and creative shipping services, collaborate with NxEdge.
                </p>
                <p>When it comes to worldwide ocean freight logistics, NxEdge is your go-to partner. With our broad network and knowledge, we provide seamless shipping solutions. From trustworthy cargo tracking to effective container management, our committed staff makes sure your items arrive at their destination promptly and safely. With NxEdge, enjoy superior ocean freight logistics. </p>
                <p>Your Passport to World-Class Global Logistics is NxEdge. Check out our all-inclusive ocean freight services for hassle-free shipping of goods internationally. Experience unmatched dependability and efficiency in handling your international shipping requirements with NxEdge. You can rely on NxEdge to carry your goods across oceans with accuracy and professionalism, from streamlined documentation to efficient container handling.
                </p>
                <h3>NxEdge Logistics: Ocean Freight Specialists </h3>
                <img src={image2} alt="" />
                <p>Your go-to resource for seamless international shipping solutions is NxEdge Ocean Freight Global Logistics. Our vast network and proficiency in maritime freight management enable us to guarantee that your goods arrives at its destination in an economical and efficient manner. We provide customised services to match your unique demands, whether you're shipping full container load (FCL) or less than container load (LCL). You may travel with peace of mind knowing that every part of the logistics process is handled by our team of skilled experts, who also handle cargo tracking, customs clearance, and documentation. Select NxEdge for ocean freight services that propel your company forward: dependable, accountable, and prompt.
                </p>
                <h5>NxEdge Logistics: Ocean Freight Specialists</h5>
                <p>Your doorway to easy international logistics solutions is NxEdge. We traverse the vast waters to connect your cargo to every part of the globe with our ocean freight services. Put your trust in our experience to manage your shipments effectively, guaranteeing prompt delivery and economical solutions. With NxEdge, you can experience the logistics of the future—where innovation and dependability collide.</p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <h6>Navigating Ocean Freight Solutions Seamlessly with NxEdge Logistics </h6>
                    <p>NxEdge: Your point of entry to seamless ocean freight solutions, linking worldwide operations to facilitate easy international cargo shipping. </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy-haul"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/air-freight">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean-freight">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/warehousing">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply-chain">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>
    <Process />
    </>
  );
}

export default Ocean;


