import React, { useState, useEffect, useRef } from "react";
import slider1 from "../Assets/4.webp";
import slider2 from "../Assets/4-1.webp";
import slider3 from "../Assets/5.webp";
import slider4 from "../Assets/5-1.webp";
import slider5 from "../Assets/1.webp";
import slider6 from "../Assets/1-1.webp";
import slider7 from "../Assets/2.webp";
import slider8 from "../Assets/2-1.webp";
import slider9 from "../Assets/3.webp";
import slider10 from "../Assets/3-1.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import { FaEnvelope, FaLocationArrow, FaPhoneAlt } from "react-icons/fa";
const FormMain = () => {
  const [sliderSettings, setSliderSettings] = useState({
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0); // Go to the first slide when the component mounts
    }
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0); // Go to the first slide when the component mounts
    }
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <section className="contact-details">
        <div className="overlay-second"></div>

        <div className="container ">
          <div className="row">
            <div className="col-xl-6 col-lg-6 ">
              <div
                className="contact-details__right"
                data-aos="fade-up"
                data-aos-anchor-placement="center-center"
              >
                <div className="sec-title">
                  <span className="sub-title">Need any help?</span>
                  <h2>World’s Leading Contract Logistics Providers.</h2>
                  <div className="text">
                    If you have any questions, requests, or inquiries regarding
                    our logistics services, please feel free to reach out to us
                    using the contact form below.
                  </div>
                </div>
                <ul className="list-unstyled contact-details__info">
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-phone-plus">
                        <FaPhoneAlt />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Have any question?</h6>
                      <Link to="tel: +1-877-200-9408">
                        <span>Free</span> +1-877-200-9408
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-envelope1">
                        <FaEnvelope />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Write email</h6>
                      <Link to="mailto:contact@nxedgelogistics.com">
                        contact@nxedgelogistics.com
                      </Link>{" "}
                      <br />
                      <Link to="mailto:invoice@nxedgelogistics.com">
                        invoice@nxedgelogistics.com
                      </Link>{" "}
                      <br />
                      <Link to="mailto:accounting@nxedgelogistics.com">
                        accounting@nxedgelogistics.com
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-location">
                        <FaLocationArrow />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Visit anytime</h6>
                      <span>
                        3018 Palominos Pass. Leander, Texas-78641 <br />
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 ">
              <div className="contact-details__left" data-aos="fade-up">
                <div className="sec-title">
                  <h2>Ask Question?</h2>
                  <span className="sub-title">
                    Fill up form below, our team will get back soon
                  </span>
                </div>

                <form id="contact_form" name="contact_form" className="">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="mb-3">
                        <input
                          name="form_name"
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <input
                          name="form_email"
                          className="form-control required email"
                          type="email"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <input
                          name="form_phone"
                          className="form-control"
                          type="text"
                          placeholder="Enter Phone"
                        />
                      </div>
                    </div>
                  </div>


                  <div className="mb-3">
                    <textarea
                      name="form_message"
                      className="form-control required"
                      rows="10"
                      placeholder="Enter Message"
                    ></textarea>
                  </div>
                 
                  <div className="disclaimer-main">
                  <p className="mb-0">
                  By providing my phone number to “NxEdge Logistics”, I agree and acknowledge that “NxEdge Logistics" may send text{""}
                    {isExpanded ? (
                      <>
                        {" "}
                        messages to my wireless phone number for any purpose. Message and data rates may apply. 
                      </>
                    ) : (
                      <>
                        {" "}
                        <span onClick={handleToggle} className="more-info">
                          Read More
                        </span>
                      </>
                    )}
                  </p>
                  {isExpanded && (
                    <p className="disclaimer-additional">
                    Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit  <Link to="/privacy-policy" className="privacy-link">"PRIVACY POLICY".</Link>  
                    </p>
                  )}
                </div>
                  <div className="mb-5">
                    <input
                      name="form_botcheck"
                      className="form-control"
                      type="hidden"
                      value=""
                    />
                    <button
                      type="submit"
                      className="theme-btn btn-style-one"
                      data-loading-text="Please wait..."
                    >
                      <span className="btn-title">Send Now</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="clients-section alt ">
        <div className="auto-container">
          <div className="sponsors-outer">
            <Slider ref={sliderRef} {...sliderSettings}>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider1} alt="" />
                  <img src={slider2} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider3} alt="" />
                  <img src={slider4} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider5} alt="" />
                  <img src={slider6} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider7} alt="" />
                  <img src={slider8} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider9} alt="" />
                  <img src={slider10} alt="" />
                </Link>
              </div>

              {/* Repeat similar structures for other clients */}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormMain;
