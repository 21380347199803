import React, { useEffect } from "react";
import { FaAngleRight, FaArrowCircleRight, FaUser } from "react-icons/fa";
import Testimonials from "./Testimonial";
import air from "../Assets/request-3.webp";
import book from "../Assets/request-4.webp";
import delivery4 from "../Assets/cost.webp";
import delivery3 from "../Assets/delivery-time.png";
import time2 from "../Assets/quote-img-new-1.webp";
import time3 from "../Assets/time-3.webp";
import time1 from "../Assets/quote-img-new-2.jpeg";
import second from "../Assets/request-one_pattern.webp";
import { Link } from "react-router-dom";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className=" text-center-padding">
            <h1 className="title">About Us</h1>
            <ul className="page-breadcrumb-two">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="about-main-section ">
        <img src={second} alt="" className="about-second-image" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="about-left">
                <div className="row clearfix">
                  <div className="column col-lg-5 col-md-5 col-sm-6">
                    <div className="" data-aos="zoom-in-down">
                      <img src={time1} alt="" />
                    </div>
                  </div>
                  <div className="column col-lg-7 col-md-7 col-sm-6">
                    <div className=" about-section-images" data-aos="zoom-in-left">
                      <img decoding="async" src={time2} alt="" />
                    </div>
                  </div>
                </div>

                <div className="about-bottom">
                  <img src={time3} alt=""  data-aos="zoom-in-right"/>

                  <div className="about-title-main">
                    <img src={delivery3} alt="" />=
                    <h6 className="">
                      Working Exprience
                      <br />
                      <span className="title-metre"> 15 Years</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner-column" data-aos="zoom-in">
                <div className="sec-title light">
                  <span className="sub-title ">ABOUT US</span>
                  <h2 className="scrub-each text-split">
                    We Provide Timely and Cost-Effective
                  </h2>

                  <p>
                    NxEdge Logistics is committed to sustainable practices and
                    reducing its environmental footprint. Initiatives
                    include:Eco-friendly Transport Solutions: Investment in
                    fuel-efficient and electric vehicles.
                  </p>
                </div>

                <div className="row g-0">
                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box">
                      <div className="title-main">
                        <h6 className="">
                        Delivered Products <br />
                          <span className="title-metre"> 5.15m</span>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box" data-aos="zoom-in-up">
                      <div className="title-main">
                        <h6 className="">
                          Clients Worldwide <br />
                          <span className="title-metre">3.15m </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

   

      <div className="about-main-section bg-image-about">
        <img src={second} alt="" className="about-second-image" data-aos="zoom-out-up" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="inner-column" data-aos="zoom-in-right">
                <div className="sec-title light">
                  <span className="sub-title text-light">EXPLORE SERVICES</span>
                  <h2 className="scrub-each text-split text-light">
                    We Provide 100% Trusted Transport Support
                  </h2>

                  <p className="text-light">
                  At NxEdge Logistics, we are committed to providing unparalleled transportation and logistics solutions tailored to meet the unique needs of our clients. With a focus on efficiency, reliability, and customer satisfaction, we ensure your goods are delivered safely and on time, every time.
                  </p>
                </div>

                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-left">
                <div className="row clearfix">
                  <div className=" about-section-images">
                    <img decoding="async" src={delivery4} alt=""  data-aos="zoom-out"/>
                  </div>
                </div>
                <div className="about-content-main">
                  <div className="about-service-main">
                    <FaUser />
                    <h6 className="">
                      Satisfied Clients
                      <br />
                      <span className="title-metre">350+</span>
                    </h6>
                  </div>

                  <div className="about-service-right">
                    <p>
                      <FaArrowCircleRight /> Managing logistics for world
                    </p>
                    <p>
                      <FaArrowCircleRight /> We Guarantee Trusted Service.
                    </p>
                    <p>
                      <FaArrowCircleRight /> Experts in technology fields
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-main-section ">
        <img src={second} alt="" className="about-second-image" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="about-left">
                <div className="row clearfix">
                  <div className=" ">
                    <img
                      decoding="async"
                      src={time2}
                      alt=""
                      className=" about-section-middle"
                    />

                    <img src={air} alt="" className="about-section-left" />
                    <img src={book} alt="" className="about-section-right" />
                    <div className="section-bottom-left">
                  <img src={delivery3} alt="" />
                  <h6 className="">
                  Trusted By
                    <br />
                    <span className=""> Our Clients</span>
                  </h6>
                </div>
                  </div>
                </div>

               
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner-about-main" data-aos="zoom-in">
                <div className="sec-title light">
                  <span className="sub-title ">REQUEST A QUOTE</span>
                  <h2 className="scrub-each text-split">
                  Effective &
Affordable
Plans
                  </h2>

                  <p>
                  Ready to elevate your logistics experience? Contact NxEdge Logistics today to learn how we can support your business with our comprehensive transportation and logistics services.
                  </p>
                </div>

                <div className="row g-0">
                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box">
                      <div className="title-main">
                        <h6 className="">
                          <span className="title-metre">Reliable  <br /></span>
                        International
                        Air Freight
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box" data-aos="zoom-in-up">
                      <div className="title-main">
                      <h6 className="">
                          <span className="title-metre">Reliable  <br /></span>
                          Warehousing
                          Services
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  


      <Testimonials />
    </>
  );
};

export default About;
