import React, { useEffect } from 'react';
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import heavyhaul from "../Assets/heavy-industrial.png"
import { Link } from 'react-router-dom';
import image1 from "../Assets/roadways.webp"
import image2 from "../Assets/24177.webp"
import image3 from "../Assets/truck.webp"
import Process from './Process';

function HeavyHaul() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
    <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Heavy Haul</h1>
        <ul className="page-breadcrumb">
         
          <li>At NxEdge Logistics, we understand the complexities and challenges of transporting oversized and heavy freight. Our Heavy Haul Services are designed to provide comprehensive solutions for moving large, heavy, and unconventional loads safely and efficiently. With state-of-the-art equipment, experienced professionals, and meticulous planning, we ensure your heavy haul projects are executed flawlessly.For reliable and efficient heavy haul services, trust NxEdge Logistics to handle your most demanding transportation needs. Contact us today to discuss your project and discover how we can deliver excellence in heavy haul logistics.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>You can rely on Heavy Haul Transportation to move the immovable. </h2>
                <p>
              <strong>  NxEdge Logistics </strong> is a leading heavy haul logistics company that is dedicated to providing unparalleled accuracy and care in the delivery of your largest and heaviest commodities. Specialised vehicles including lowboys, RGNs (Removable Gooseneck Trailers), perimeter trailers, and dual-lane trailers are among the many pieces of equipment in our large fleet that are built to meet even the most exacting haulage requirements. RGNs enable simple front loading and unloading, which is great for large construction equipment, while Lowboys offer a low deck height that is appropriate for tall and heavy machinery. For the safest transportation of very heavy and huge items, perimeter trailers distribute weight evenly, while dual-lane trailers provide the most stability for the heaviest loads. Our transportation solutions, which include route surveys, permits, and escorts, are customised to your specific needs in order to ensure safe, effective, and timely delivery from planning through implementation. Your biggest goods are in the most capable hands thanks to NxEdge Logistics' team's vast experience, unshakable dedication to safety, and effective logistics.

                </p>
               
                <h3>The Most Reliable And Secure Heavy Haul Transportation </h3>
                <img src={image2} alt="" />
                <p>Our transportation solutions, which include route surveys, permits, and escorts, are customised to your specific needs in order to ensure safe, effective, and timely delivery from planning through implementation. Your biggest goods are in the most capable hands thanks to NxEdge Logistics' team's vast experience, unshakable dedication to safety, and effective logistics.
                </p>
                <h5>Are you searching for the best Heavy Haul Transportation  for reliable
                  shifting? </h5>
                <p>You may take advantage of our vast experience, cutting-edge machinery, and a devoted team of specialists who are committed to excellence while working with <strong>NxEdge Logistics</strong>. Our track record of successfully completed deliveries attests to our dependability and knowledge. Your most difficult cargoes are in the finest hands when you select NxEdge Logistics as your heavy haul transportation partner since we are the most dependable and secure option. Allow us to transform your reality.
                </p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <p>For on-time delivery, the most economical and dependable forms of transportation must be chosen. Depending on the distance, urgency, and expense, each means of transportation—air, sea, or land—offers benefits and drawbacks that need to be carefully considered.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy-haul"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/air-freight">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean-freight">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/ware">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply-chain">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>

    <Process />
    </>
  );
}

export default HeavyHaul;


