import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/packaging1.webp"
import image2 from "../Assets/packaging2.webp"
import image3 from "../Assets/packaging3.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/nefab1.webp"

function Packaging() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
         <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Packaging</h1>
        <ul className="page-breadcrumb">
         
          <li>At NxEdge Logistics, we understand that proper packaging is crucial for the safe and efficient transportation of your goods. Our comprehensive packaging solutions ensure that your products are protected, secure, and ready for their journey, whether they are traveling across town or across the globe.Enhance the safety, efficiency, and appeal of your shipments with NxEdge Logistics' comprehensive packaging solutions. Contact us today to learn how we can support your business with our expert packaging services.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

      <section className="single-service">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>Packaging Solutions Tailored for Every Shipment </h2>
                  <p>
                  We redefine excellence in global logistics at NxEdge. Our state-of-the-art technology and creative approach enable us to provide unmatched packaging solutions that are customised to your requirements. Our skilled team makes sure your goods arrives at its destination promptly and safely, handling everything from smooth transportation to effective supply chain management. NxEdge invites you to explore the logistics of the future.
                  </p>
                  <p>


Greetings from NxEdge, your leading worldwide logistics partner. Our cutting-edge solutions and unmatched knowledge allow us to redefine excellence in supply chain management and transportation. Our team makes sure that your items arrive at their destination quickly and securely, from smooth freight forwarding to careful cargo handling. With NxEdge, experience the logistics of the future. Count on us to deliver above and above. </p>
                  <p>Greetings from NxEdge, the leading source for state-of-the-art logistics solutions. Our continuous dedication to excellence and robust global network enable us to provide unmatched packaging services that are customised to meet your specific needs. You can rely on NxEdge to precisely and expertly handle the difficulties of the contemporary global market, from sophisticated supply chain management to smooth goods moving. With NxEdge, discover the potential of innovation and dependability right now.</p>
                  <h3> Safe Delivery with Expert Packaging Strategies </h3>
                  <img src={image2} alt="" />
                  <p>We redefine excellence in logistics services at NxEdge. Our dedication to efficiency and innovation allows us to provide streamlined, dependable solutions for all of your international shipping requirements. Your shipment will arrive at its destination promptly and safely thanks to our extensive transportation network. For end-to-end logistical support, rely on NxEdge for everything from ground transport to air and ocean freight. NxEdge invites you to explore the logistics of the future. Together, let's progress your company.
                  </p>
                  <h5>Innovative Packaging Techniques for Secure Transportation</h5>
                  <p>
                  Greetings from NxEdge, your leading worldwide logistics partner. At NxEdge, our unwavering dedication to effectiveness, dependability, and innovation redefines excellence in logistics services. We provide seamless solutions that are customised to satisfy all of your transportation needs thanks to our worldwide network that spans continents. NxEdge can transport your cargo quickly and precisely, whether it's large haulage, rapid delivery, or specialised goods. With NxEdge, you can experience the logistics of the future—where every shipment is handled with the highest care and knowledge.</p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>Tailored Packaging Services to Meet Your Specific Requirements</h6>
                      <p>
                      NxEdge: Providing Global Logistics Excellence. Redefining Innovation, Efficiency, and Expertise. Seamless Worldwide Transportation Solutions from Your Reliable Partner. </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy-haul"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/air-freight">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean-freight">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/warehousing">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply-chain">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
          </div>
        </div>
      </section>

      <Process />
    </>
  );
}

export default Packaging;


