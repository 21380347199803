import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/warehouse1.webp"
import image2 from "../Assets/96930485.webp"
import image3 from "../Assets/warehouse2.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/wareba.webp"


const Warehousing =()=> {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
<section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Warehousing</h1>
        <ul className="page-breadcrumb">
         
          <li>At NxEdge Logistics, we understand that efficient warehousing is a critical component of a successful supply chain. Our state-of-the-art facilities and advanced inventory management systems ensure that your products are stored securely and are readily accessible when needed. Whether you need short-term storage or long-term warehousing, we provide flexible and scalable solutions tailored to your specific needs.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>Exceptional Inventory Control and Warehousing with NxEdge Logistics</h2>
              <p> Leading warehouse and drayage solutions are provided by NxEdge as part of our all-inclusive international logistics services. We manage the seamless transportation and storage of commodities around the globe with a dedication to efficiency and dependability. You can rely on NxEdge to provide customised logistics solutions that address your unique requirements and guarantee seamless supply chain operations.
                </p>
                <p>NxEdge provides complete warehouse and drayage solutions for international logistics requirements. Our knowledgeable staff guarantees efficient product storage and transportation, maximising supply chain effectiveness. With cutting edge facilities and global strategic alliances, we offer dependable services catered to your particular needs. You can rely on NxEdge for unmatched logistical expertise.</p>
                
                <h3>Greetings from NxEdge, your go-to source for international logistics solutions. </h3>
                <img src={image2} alt="" />
                <p>Our all-inclusive services offer effective warehousing and drayage options made to match your unique requirements. Our global network and unwavering dedication to quality assurance guarantee the smooth shipment and storage of your belongings. Put your trust in NxEdge for dependable, reasonably priced logistics services that propel your company forward. Try NxEdge now and see the difference."</p>
                <h5>Advanced Warehousing and Drayage Methods for Optimal Logistics </h5>
                <p>We at NxEdge redefine excellence in storage and drayage services for international logistics. We are dedicated to providing dependable and efficient solutions that are specifically customised to meet your demands. Modern technology combined with our vast network guarantees smooth international shipping and storage of goods. For professional handling, safe storage, and efficient logistics solutions, rely on NxEdge." </p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <h6>Effective Warehousing and Drayage Solutions at NxEdge for Your Logistics Requirements </h6>
                    <p>
                    For warehousing and drayage needs worldwide, NxEdge provides professional solutions. Our extensive services guarantee smooth international shipping and storage of products. For effective, dependable, and reasonably priced logistics solutions that are customised to meet your company's needs, rely on NxEdge.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy-haul"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/air-freight">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean-freight">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/warehousing">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply-chain">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>


    <Process />
    </>
  );
}

export default Warehousing;


