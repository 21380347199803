import React, {useEffect} from 'react'
import { Link } from "react-router-dom";

import { FaAngleDoubleRight, FaAngleDoubleUp, FaAngleUp, FaFacebook, FaInstagram, FaMapPin, FaPaperPlane, FaPinterestP, FaTwitter } from 'react-icons/fa';
import thumb1 from "../Assets/gallery-thumb-1.webp"
import thumb2 from "../Assets/gallery-thumb-2.webp"
import thumb3 from "../Assets/gallery-thumb-3.webp"


const Footer = () => {
  useEffect(() => {
    const scrollToTopButton = document.querySelector('.scroll-to-top');
    scrollToTopButton.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    });
    return () => {
        scrollToTopButton.removeEventListener('click', () => {});
    };
}, []);


  return (
    <>
     <div className="widgets-section">
     <div class="bg bg-image" ></div>
      <div className="container">
        <div className="row paading-main">

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget about-widget">
            <h4 className="widget-title">NxEdge Logistics</h4>
             
              <div className="text-footer text-justify">With a global reach, NxEdge specializes in air freight, supply chain management serving a diverse range of industries worldwide.</div>
              <div className="widget-content">
             
                
            </div>
              <Link to="/about" className="theme-btn btn-style-one"><span className="btn-title">About Us</span></Link>
            </div>
            
          </div>

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget links-widget">
              <h4 className="widget-title">Our Service</h4>
              <div className="widget-content">
                <ul className="user-links">
                  <li><FaAngleDoubleRight /> <Link to="/heavy-haul">Heavy haul</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/air-freight">Air Freight</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/ocean-freight">Ocean Freight</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/warehousing">Drayage And Warehousing</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/supply-chain">Supply Chain</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/packaging">Packaging</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget links-widget">
              <h4 className="widget-title">Connect with Us</h4>
              <div className="widget-content">
             
                <ul className="user-links">
                  <li><FaAngleDoubleRight /> <Link to="/heavy-haul">About Us</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/privacy-policy">Privacy & Policy</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/disclaimer">Disclaimer</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="#">Our Projects</Link></li>
                  <div className="widget-content">
                <div className="insta-gallery mt-4">
                  <figure className="image"><Link to="/service"><img src={thumb1} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb2} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb3} alt='' /></Link></figure>
                 
                </div>
              </div>
                </ul>
              </div>
            </div>
          </div>

          

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget newsletter-widget">
              <h4 className="widget-title">Newsletter</h4>
              <div className="text-footer">Subscribe our newsletter to get our latest update &amp; news</div>

              <div className="newsletter-form">
                <div className="form-group">
                  <input type="email" name="Email" placeholder="Email....." required="" />
                  <button type="submit" className="form-btn"><FaPaperPlane /></button>
                </div>
              </div>

             
           <p>Follow Us :</p>
              <ul className="social-icon-one light">
                        <li><Link to="https://www.facebook.com/"><FaFacebook /></Link></li>
                        <li><Link to="https://www.twitter.com/"><FaTwitter /></Link></li>
                        <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>
                        <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>
                    </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
                <div className="inner-container">
                    
                    <div className="copyright-text">© 2024 NxEdge Logistics. All Rights Reserved. Developed By NxEdge Solutions</div>

                    <div className="scroll-to-top scroll-to-target" data-target="html" style={{ display: 'inline' }}>
                       <FaAngleDoubleUp />
                    </div>
                  
                </div>
        </div>
    </div> 
   
    </>
  )
}

export default Footer
